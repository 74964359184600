<template>
    <div class="container content">
        <top-navigation-bar
            title="Help Deciding"
            right-nav="home"
        />
        <div class="d-flex route-btn-view pb-5">
            <button
                v-for="(button,key) in routes"
                :key="key"
                class="route-btn lg double"
                @click="helpChoice(button)"
            >
                {{ button.title }}
            </button>
        </div>
        <modal
            ref="modalMeanHelp"
            :title="modalTitle"
            style-class="modal-top med"
            modal-type="general"
        >
            <template #body>
                <div
                    v-if="meanSelect === 'One Mean'"
                    class="mt-5"
                >
                    <button
                        v-for="(button,key) in oneSampleFormulas"
                        :key="key"
                        class="route-btn lg double"
                        @click="oneMeanChoice(button)"
                    >
                        {{ button.display }}
                    </button>
                </div>
                <div
                    v-else
                >
                    <b-list-group
                        class="btn-group-toggle btn-group-vertical"
                        data-toggle="buttons"
                    >
                        <label
                            v-for="(picker, key) in pickers.type"
                            :key="key"
                            class="btn btn-outline-secondary p-3"
                            :class="pick.type === key ? ' active': ''"
                        >
                            <input
                                v-model="pick.type"
                                type="radio"
                                class="btn-group-toggle"
                                :value="key"
                            >
                            {{ picker }}
                        </label>
                        <br>
                        <label
                            v-for="(picker, key) in pickers.sample"

                            :key="key"
                            class="btn btn-outline-secondary p-3"
                            :class="pick.sample === key ? ' active': ''"
                        >
                            <input
                                v-model="pick.sample"
                                type="radio"
                                class="btn-group-toggle"
                                :value="key"
                            >
                            {{ picker }}
                        </label>
                        <br>
                        <label
                            v-for="(picker, key) in pickers.variance"
                            v-show="toggleVariance"
                            :key="key"
                            class="btn btn-outline-secondary p-3"
                            :class="pick.variance === key ? ' active': ''"
                        >
                            <input
                                v-model="pick.variance"
                                type="radio"
                                class="btn-group-toggle"
                                :value="key"
                            >
                            {{ picker }}
                        </label>
                    </b-list-group>
                    <div class="sticky-bottom m-3 mt-5">
                        <button
                            class="action-btn btn-block"
                            block
                            @click="twoMeansChoice()"
                        >
                            Done
                        </button>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import Modal from '@/components/modal.vue'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'ChoiceHelp',
    components: {
        TopNavigationBar,
        Modal
    },
    data () {
        return {
            allData: {},
            routes: [],
            meanSelect: '',
            pickers: {
                type: {
                    ind: 'INDEPENDENT',
                    dep: 'DEPENDENT',
                },
                sample: {
                    large: 'LARGE SAMPLES',
                    small: 'SMALL SAMPLES',
                },
                variance: {
                    notEq: 'ASSUME ≠ VARIANCES',
                    eq: 'ASSUME = VARIANCES',
                },
            },
            pick: {
                type: 'ind',
                sample: 'large',
                variance: 'notEq',
            },
            oneSampleFormulas: [
                { name: 'OneZ', title: 'One Mean', display: 'Large' },
                { name: 'OneT', title: 'One Mean', display: 'Small' },
                { name: 'OneV', title: 'One Variance' },
            ],
            twoSampleFormulas: [
                { name: 'TwoV', title: 'Two Variances' },
                { name: 'TwoILarge', title: 'Difference in Means' },
                { name: 'TwoISmallEqual', title: 'Difference in Means' },
                { name: 'TwoISmallNotEqual', title: 'Difference in Means' },
                { name: 'TwoDLarge', title: 'Paired Difference in Means' },
                { name: 'TwoDSmall', title: 'Paired Difference in Means' },
            ],
        }
    },
    computed: {
        modalTitle () {
            var output
            if (this.meanSelect === 'One Mean') {
                output = 'Mean'
            } else {
                output = 'Help Deciding'
            }
            return output
        },
        computationPicked () {
            var { type, sample } = this.pick
            var pickValsAll = _.valuesIn(this.pick)
            var computationPicked
            switch (true) {
            case _.isEqual(pickValsAll, ['ind', 'small', 'eq']):
                computationPicked = 'TwoISmallEqual'
                break
            case _.isEqual(pickValsAll, ['ind', 'small', 'notEq']):
                computationPicked = 'TwoISmallNotEqual'
                break
            case _.isEqual([type, sample], ['ind', 'large']):
                computationPicked = 'TwoILarge'
                break
            case _.isEqual([type, sample], ['dep', 'small']):
                computationPicked = 'TwoDSmall'
                break
            case _.isEqual([type], ['dep']):
                computationPicked = 'TwoDLarge'
                break
            default:
                computationPicked = ''
            }
            return computationPicked
        },
        toggleVariance () {
            var { type, sample } = this.pick
            return _.isEqual([type, sample], ['ind', 'small'])
        },
    },
    beforeMount () {
        var store = localStore.getStore('confidence-data')
        var choice = store.choice
        if (store) {
            _.assign(this, { allData: store })
            if (choice) {
                var buttons
                if (choice.name === 'one') {
                    buttons = [
                        { title: 'One Mean' },
                        { title: 'Proportions' },
                        { title: 'One Variance' },
                    ]
                } else {
                    buttons = [
                        { title: 'Two Means' },
                        { title: 'Two Proportions' },
                        { title: 'Two Variances' },
                    ]
                }
                _.assign(this, { routes: buttons })
            }
        }
    },
    methods: {
        helpChoice (button) {
            var formula
            if (button.title === 'One Mean' || button.title === 'Two Means') {
                _.assign(this, { meanSelect: button.title })
                this.$refs.modalMeanHelp.show()
            } else {
                if (button.title === 'Proportions') {
                    formula = { name: 'OneP', title: 'One Proportion' }
                } else if (button.title === 'Two Proportions') {
                    formula = { name: 'TwoP', title: 'Difference in Proportions' }
                } else {
                    formula = _.find(this.oneSampleFormulas, { title: button.title })
                    if (!formula) {
                        formula = _.find(this.twoSampleFormulas, { title: button.title })
                    }
                }
                this.navigate(formula)
            }
        },
        oneMeanChoice (button) {
            var formula = _.omit(button, 'display')
            this.navigate(formula)
        },
        twoMeansChoice () {
            var { twoSampleFormulas } = this
            var formula = _.filter(twoSampleFormulas, { name: this.computationPicked })
            this.navigate(formula[0])
        },
        navigate (formula) {
            var { allData } = this
            _.set(this.allData, 'formula', formula)
            localStore.setStoreItem('confidence-data', allData)
            this.$router.push({ name: 'confidence/ci-input' })
        }
    },
}
</script>
